import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../views/utils/axiosInstance';
import axios from 'axios';
export const fetchCities = async () => {
    try {
        const response = await axiosInstance.get(`/city/getCities`);
        return response.data;
    } catch (error) {
        console.error('Error fetching cities:', error);
        throw error;
    }
};

export const sendProjectQuery = async (formData) => {
    // console.log(formData)
    try {
        const response = await axiosInstance.post(`/userQuery/addQuery`, formData);
        return response;
    } catch (error) {
        // console.error('Error sending data:', error);
        throw error;
    }
};
const FooterBottom = () => {
    
    const navigate = useNavigate();
    const [showButton, setShowButton] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsScrolled(true);
        }
        if (window.scrollY > 100) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [cities, setCities] = useState([]);
    useEffect(() => {
        const getCities = async () => {
            try {
                const data = await fetchCities();
                setCities(data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };
        getCities();
    }, []);

    const urlParams = new URLSearchParams(window.location.search);
    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [formloading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        phoneNumber: '',
        projectName: 'Query',
    });
    const handleClose = () => setShow(false);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePhoneChange = (phone) => {
        setFormData(prevData => ({
            ...prevData,
            phoneNumber: phone
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isChecked) {
            return; // Do not submit if checkbox is not checked
        }

        if (formData.phoneNumber === "1234567890" || formData.phoneNumber === "0123456789") {
            setSuccessMessage('Please enter a valid phone number.');
            setTimeout(() => {
                setSuccessMessage(''); // Clear message after some time
            }, 2000);
            return;
        }


        const data = [
            { "Attribute": "FirstName", "Value": formData.Name },
            { "Attribute": "EmailAddress", "Value": formData.Email },
            { "Attribute": "Phone", "Value": formData.phoneNumber },
            { "Attribute": "mx_Project_Name", "Value": formData.projectName },
            { "Attribute": "Source", "Value": urlParams.get('source') || "" },
            { "Attribute": "mx_City", "Value": urlParams.get('city') || "" },
            { "Attribute": "mx_Platter", "Value": urlParams.get('platter') || "" },
            { "Attribute": "mx_Description", "Value": urlParams.get('description') || "" },
            { "Attribute": "mx_ip", "Value": urlParams.get('ip') || "" }
        ];

        try {
            setFormLoading(true);

            await sendProjectQuery(formData);
            const response = await axios.post(
                'https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r3801fc4b9130c8ecddc7b4e9ca3887e8&secretKey=70602eae4ab9a2e6dbfcfc734168007a1e0dfdd6',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            const result = response.data;

            if (result.Status === 'Success') {
                setSuccessMessage('Form submitted successfully!');
                setTimeout(() => {
                    setSuccessMessage(''); 
                }, 2000);
                setShow(false);
                handleClose(); 
                navigate('/thank-you');

            } else {
                console.log("error")
            }
          
            setFormData({
                Name: '',
                Email: '',
                phoneNumber: '',
                projectName: 'Query',
            });

            
        } catch (error) {
            console.log(error)
            setSuccessMessage('Failed to submit the form.');
            setFormData({
                Name: '',
                Email: '',
                phoneNumber: '',
                projectName: 'Query',
            });
            setTimeout(() => {
                setSuccessMessage(''); // Clear message after some time
            }, 3000);
        } finally {
            setFormLoading(false);
        }
    };
    return (
        <>
            <footer className="footer-area overflow-hidden w-100">
                <div className="container-lg">

                    <div className="padding foot-links">
                        <div className="inner">
                            <div className="row gap-row">
                                <div className="col foot-menu">
                                    <div className="insideBox">
                                        <h6>Projects</h6>
                                        <ul className="list-inline">
                                            {/* <li><Link to="/projects/luxury">Luxury</Link></li> */}

                                            <li><Link to='/projects/commercial'>Commercial</Link></li>
                                            <li><Link to="/projects/new-launch">New Launches</Link></li>
                                            <li><Link to='/projects/residential'>Residential</Link></li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col foot-menu">
                                    <div className="insideBox">
                                        <h6>Company</h6>
                                        <ul className="list-inline">
                                            <li><Link to='/about-us'>About Star Estate</Link></li>

                                            <li><Link to='/faq'>FAQs</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col foot-menu">
                                    <div className="insideBox">
                                        <h6>Media</h6>
                                        <ul className="list-inline">
                                            <li><Link to='/advertisements'>Advertisements</Link></li>
                                            <li><Link to='/awards'>Awards</Link></li>
                                            <li><Link to='/blogs'>Blogs</Link></li>
                                            <li><Link to='/events'>Events</Link></li>
                                            <li><Link to='/news'>News</Link></li>



                                        </ul>
                                    </div>
                                </div>
                                <div className="col foot-menu">
                                    <div className="insideBox">
                                        <h6>Explore</h6>
                                        <ul className="list-inline">
                                            <li><Link to='/buyer-guide/'>Buyer Guide</Link></li>
                                            <li><Link to='/careers'>Careers</Link></li>
                                            <li><Link to='/contact-us'>Contact Us</Link></li>

                                            <li><Link to='/desclaimer'>Disclaimer</Link></li>
                                            <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                                            <li><Link to='/sitemap'>Sitemap</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col foot-menu socialBox">
                                    <div className="insideBox">
                                        <h6>Follow Us</h6>
                                        <ul className="list-inline socialIcons solid">
                                            <li className="list-inline-item"><a href="https://www.facebook.com/starestate.in" target="_blank" data-toggle="tooltip" data-placement="bottom" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="list-inline-item"><a href="https://www.instagram.com/starestate_official/" target="_blank" data-toggle="tooltip" data-placement="bottom" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                                            <li className="list-inline-item"><a href="https://www.linkedin.com/company/star-estate" target="_blank" data-toggle="tooltip" data-placement="bottom" title="LinkedIn"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li className="list-inline-item"><a href="https://twitter.com/starestate2" target="_blank" data-toggle="tooltip" data-placement="bottom" title="X Twitter"><i className="fab fa-x-twitter"></i></a></li>
                                            <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCwfDf7Ut8jrkjiBeRnbZUPw" target="_blank" data-toggle="tooltip" data-placement="bottom" title="YouTube"><i className="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container-lg d-flex justify-content-end">
                        <div className="copyrights flex-row justify-content-between w-100">
                            <p>© Star Estate 2024</p>
                            <p>Designed &amp; Developed by: <img src="../assets/images/ecis.webp" /></p>
                        </div>
                    </div>
                </div>

                <div
                    className="button-top"
                    style={{
                        opacity: isScrolled ? (showButton ? 1 : 0) : 0,
                        pointerEvents: showButton ? 'auto' : 'none',
                        transition: 'opacity 0.5s ease',
                    }}
                    onClick={scrollToTop}
                >
                    <i className="fa fa-chevron-up" style={{ color: 'white' }}></i>
                </div>
                <div className="footer-enquiryBtn d-flex d-sm-none">
                    <a className="monCall" id="mobPhone" href="tel:+917088470884"><strong><i className="fa fa-phone"></i> Call</strong></a>
                    <a id="mobEnquiry" href="#formModal" data-bs-toggle="modal"><strong><i className="fa fa-envelope"></i> Enquire</strong></a>
                    <a className="whatsCall" href="https://api.whatsapp.com/send?phone=+917088470884&text=Hello,%20I’m%20interested%20in%20finding%20out%20more.%20Can%20you%20provide%20further%20information?" target="_blank" rel="noopener noreferrer">
                        <strong><i className="fab fa-whatsapp"></i> WhatsApp</strong>
                    </a>
                </div>
            </footer>
            <div
                className={`modal fade ${show ? 'show' : ''}`}
                id="formModal"
                tabIndex="-1"
                role="dialog"
                data-bs-backdrop="true"
                data-bs-keyboard="true"
            >                 <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {/* class to className */}
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-header">
                            <h6 className="modal-title text-primary">Please fill the given form.</h6>
                            {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                        </div>
                        <div className="modal-body">
                            <div className="form">
                                <form className="form-container" id="contact_form" method="post" onSubmit={handleSubmit}>
                                    <p className="status mb-0 text-warning"></p>
                                    <div className="form-row">
                                        <div className="col-md-12 form-group">
                                            {/* for to htmlFor */}
                                            <label htmlFor="name">Name<sup className="text-danger" >*</sup></label>
                                            <input type="text" className="form-control bg-white" id="Name" name="Name" value={formData.Name}
                                                onChange={handleInputChange}
                                                required />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label htmlFor="email">Email<sup className="text-danger">*</sup></label>
                                            <input type="email" className="form-control bg-white" name="Email" id="Email" value={formData.Email}
                                                onChange={handleInputChange}
                                                required />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label htmlFor="mobile">Mobile<sup className="text-danger">*</sup></label>
                                            <input type="tel" className="form-control bg-white" name="phoneNumber" id="phoneNumber" value={formData.phoneNumber}
                                                onChange={handleInputChange}
                                                required />
                                        </div>
                                        <div className="col-12 form-group">
                                            <div className="custom-control d-flex ml-3 custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customCheck1"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="custom-control-label m-2" htmlFor="customCheck1">
                                                    I accept the Terms & Conditions.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-align-center w-auto formFooter readmore mt-0">
                                            {formloading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) :
                                                (<>
                                                    <input type="hidden" name="contact_action" value="active" />
                                                    <input type="hidden" id="pagename" name="pagename" value="" />
                                                    <input type="hidden" name="utm_source" value="" />
                                                    <input type="hidden" name="utm_medium" value="" />
                                                    <input type="hidden" name="utm_campaign" value="" />

                                                    <button type="submit" className="button">Submit</button></>)}
                                        </div>
                                        <div className="col-md-12 modal-call text-center mt-4 d-flex align-items-center justify-content-center" style={{ gap: "24px" }}>
                                            <h6 className="mb-0">Request a Call Back</h6>

                                            <div className="readmore ml-3 mt-0">
                                                <a
                                                    href="tel:+917088470884"
                                                    className="button"
                                                >
                                                    <i className="fa fa-phone"></i>
                                                    <span id="ivrmodal">+91 70884 70884</span>
                                                </a>
                                            </div>


                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default FooterBottom;
