import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../../widgets/header';
import { Helmet } from 'react-helmet';

function TopEstateBangalore() {

    const Faqs = [
        {
            faqQuestion: "Does Star Estate leading real estate broker in Bangalore offer property options across the city?",
            faqAnswer: "Yes. The company deals in property market across the city and offer inventory in Whitefield, Devenahalli, and Begur. Other locations at which the company has its presence are Varthur, Indiranagar, and Bellary Road. Kanakpura Road, Kodihalli, Sarjapur Road, and Yelahanka."
          },
          {
            faqQuestion: "Does Star Estate offer RERA-approved projects in Bangalore?",
            faqAnswer: "Of course. Star Estate offers RERA-approved projects in Bangalore."
          },
          {
            faqQuestion: "Does Star Estate offer 4BHK luxury apartments in Bangalore? How can I make a site visit to them?",
            faqAnswer: "Star Estate offers 4BHK luxury apartments in Bangalore. To schedule a site visit to them, connect with our sales manager now."
          },
          {
            faqQuestion: "Where Star Estate is located in Bangalore?",
            faqAnswer: "Star Estate is located in Bangalore’s HSR locality."
          },
          {
            faqQuestion: "Star Estate works with which developers in Bangalore?",
            faqAnswer: "In Bangalore, Star Estate works with Embassy Group, Assetz Property, Purvankara Group, Prestige Group, Birla Estates, and Godrej Properties."
          }
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        // If the clicked item is already active, deactivate it by setting the activeIndex to null
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };


    return (
        <div>
           <Helmet>
                <title>Best Real Estate Company in Bangalore, Consultant, Top Broker </title>
                <meta name="description" content='Discover the top-rated leading real estate company in Bangalore, offering expert consultancy services and backed by seasoned brokers. Find your ideal property. ' />
                <meta name="keywords" content='Real Estate Company In Bangalore, Best Real Estate Company In Bangalore, Leading Real Estate Company In Bangalore, Top Real Estate Company In Bangalore, Real Estate Broker In Bangalore, Top Real Estate Broker In Bangalore, Leading Real Estate Broker In Bangalore, Best Real Estate Broker In Bangalore, Real Estate Consultant In Bangalore, Top Real Estate Consultant In Bangalore, Best Real Estate Consultant In Bangalore, Leading Real Estate Consultant In Bangalore ' />
            </Helmet>
          <Header />
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-emi-calculator-m.jpg" />
                    <img src="../assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Top real estate company in Bangalore</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h1 className="h3 mb-0">Top real estate company in Bangalore</h1>
                    </div>     

                        <div className="buyer-text-box mb-4">                       
                        <article>
        <p className='mb-4'><span className="text-green">Star Estate</span> is the top real estate consultant in Bangalore to connect for investors.</p>
        <p className='mb-4'>The 12-year old company is passionate about finding the right property for clients. The company aims to assist property investors with the sense of perfect investment. Thus, Star Estate has a bandwagon of highly skilled professionals.</p>
        <p className='mb-4'>Founded by Mr. Vijay Jain, the company emphasizes use of technology. It enables the organization to assist investors with dream spaces. The organisation uses Artificial Intelligence (AI), Virtual Reality (VR), Social media, and CRM. It aids Star Estate to have a strong presence in the property market.</p>
        <p className='mb-4'>The company is a RERA-registered authority to operate in the sector and partners with reputed developers only.</p>
        <p className='mb-4'>Star Estate, the <strong>top real estate consultant in Bangalore</strong>, works on RERA-registered projects.</p>
      </article>
          
          <div className="row mx-0">
      <div className="col-md-6 mv-box px-4">
        <section>
          <h4>Who We Are</h4>
          <p className='mb-4'>Star Estate is a Noida-based real estate consultant. The transparent and customer-friendly work approach lures investors. With fair-priced deals and personalized attention to property investors, Star Estate has become the leading <strong>real estate broker in Bangalore</strong></p>
          <h4>Why Choose Us</h4>
          <p className='mb-4'>Star Estate focuses on understanding client requirements and assisting them until the best is found. The organisation lists focus points about the reasons to buy a property and select inventory accordingly. Thus, the investor’s time and money are used efficiently. An immovable asset adds to the wealth creation list.</p>
          <h4>How We Work</h4>
          <p className='mb-4'>Star Estate, the <strong>top real estate consultant in Bangalore</strong>, has a result-driven work approach. The professionals here aim to achieve accomplishments. With the enthusiasm to meet clients’ expectations, the entire team works in a disciplined manner. From the time the query is received to after-sales assistance, Star Estate, the <strong>top real estate broker in Bangalore</strong>, takes care of everything.</p>
          <h4>Locate Us</h4>
          <p className='mb-4'>Star Estate, the best real estate consultant in Bangalore, has its office in the HSR locality. You can visit the office. Or connect with our sales manager over a call.</p>
        </section>
      </div>
      <div className="col-md-6 mv-img px-0">
        <img src="https://www.starestate.in/assets/images/pune.webp" className="object-cover h-100" alt="Vision" />
      </div>
    </div>
         
         <div className="row mx-0 flex-row-reverse">
    <div className="col-md-6 mv-box px-4 pt-4">
      <section>
        <h2>Star Estate Top Real Estate Broker in Bangalore Partners With</h2>
        <p className='mb-4'>Star Estate is a name that believes in attaining goals while keeping ethos uncompromised.</p>
        <p className='mb-4'>The company has a zest to meet promises. Thus, they partner with the patron of the same ideology. Star Estate, the top real estate broker in Bangalore, works with reputed developers.</p>
        <p className='mb-4'>The organisation partners with Godrej Properties, Shriram Properties, Prestige Group, and Assetz Property. Along with Assetz Property, Birla Estates, Embassy Group, and Purvankara Group.</p>
        <p className='mb-4'>From the long-time collaboration with reputed developers in Bangalore, the company sets new benchmarks. Star Estate won many laurels and accolades for assisting property investors and closing successful deals.</p>
      </section>
    </div>
    <div className="col-md-6 mv-img px-0">
      <img src="https://www.starestate.in/assets/images/pune-2.webp" className="object-cover h-100" alt="Mission" />
    </div>
  </div>
 <div className="row mx-0">
    <div className="col-md-6 mv-box px-4 pt-4">
      <section>
        <h2>Star Estate – Best Real Estate Company in Bangalore | Why It is Different</h2>
        <p className='mb-4'>
          The company narrates the vision to accomplish the property purchase dream of every property investor and buyer. 
          Thus, the seasoned professionals at Star Estate analyze the property requirements to churn the inventory. 
          As a result, the best available properties get listed in clients’ price brackets.
        </p>
        <p className='mb-4'>
          Star Estate has a team of seasoned professionals passionate about going the extra mile to meet client satisfaction.
          Hence, the organisation with a sturdy pan-India presence offers post-sale assistance.
        </p>
      </section>
    </div>
    <div className="col-md-6 mv-img px-0">
      <img 
        src="https://www.starestate.in/assets/images/top-real-estate-company-in-gurgaon2.webp" 
        className="object-cover h-100" 
        alt="Mission" 
      />
    </div>
  </div>
  <div className="row mx-0 flex-row-reverse">
    <div className="col-md-6 mv-box px-4 pt-4">
      <section>
        <h2>Business Locations | Star Estate | Best Real Estate Broker in Bangalore</h2>
        <p className='mb-4'>Property hunt isn’t easy in India’s maiden Silicon Valley.</p>
        <p className='mb-4'>
          It is ideal to have the best <strong>real estate broker in Bangalore</strong> by your side to explore property options. 
          Star Estate agrees there are apprehensions about property investment.
        </p>
        <p className='mb-4'>
          There is a dedicated team to resolve property purchase apprehensions. End your dream run for property in Bangalore with Star Estate across locations.
        </p>
        <p className='mb-4'>
          The organisation deals in real estate projects at Whitefield, Devenahalli, and Begur. Other locations at which the company offer projects are Varthur, Indiranagar, and Bellary Road. 
          Kanakpura Road, Kodihalli, Sarjapur Road, and Yelahanka are other prominent places where Star Estate operates.
        </p>
      </section>
    </div>
    <div className="col-md-6 mv-img px-0">
      <img 
        src="https://www.starestate.in/assets/images/pune-2.webp" 
        className="object-cover h-100" 
        alt="Mission" 
      />
    </div>
  </div>
        </div>
        <div className="w-100 padding bg-gray-gradient-box section-faq">
                {Faqs.length > 0 && (
                    <div className="container-lg">
                        <div className="heading mx-auto text-center">
                            <h2 className="mb-0">FAQs</h2>
                        </div>
                        <div className="">
                            <div className="accordion-wrapper" id="myAccordion">
                                {Faqs.map((faq, index) => {
                                    const faqIndex = index + 1;
                                    const isActive = activeIndex === faqIndex; // Check if this item is active

                                    return (
                                        <div className="accordion-item" key={faqIndex}>
                                            <div className="accordion-header" id={`heading${faqIndex}`}>
                                                <button
                                                    className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => handleToggle(faqIndex)}
                                                    aria-expanded={isActive ? "true" : "false"}
                                                    aria-controls={`collapse${faqIndex}`}
                                                >
                                                    <span className='text-primary'>Q{faqIndex}:</span> <div dangerouslySetInnerHTML={{ __html: faq.faqQuestion }} />
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                            <div
                                                id={`collapse${faqIndex}`}
                                                className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                aria-labelledby={`heading${faqIndex}`}
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <div className="card-body">
                                                        <div>{faq.faqAnswer}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}</div></div></div></div>
          
        
    )
}
export default TopEstateBangalore