import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../../widgets/header';
import { Helmet } from 'react-helmet';

function TopEstateDelhi() {
    const Faqs = [
        {
            faqQuestion: "Where is luxury property in Delhi located?",
            faqAnswer: "The luxury property in Delhi is located in West Delhi, Connaught Place, and South Delhi."
        },
        {
            faqQuestion: "Is property in Delhi RERA-compliant?",
            faqAnswer: "Yes, the property in Delhi is RERA-compliant."
        },
        {
            faqQuestion: "When can I make a site visit to a residential property in Delhi?",
            faqAnswer: "Connect with our sales manager to plan a site visit to a residential property in Delhi."
        },
        {
            faqQuestion: "Which developers offer inventory in Delhi, the national capital?",
            faqAnswer: "Godrej Properties and DLF Group offer inventory in the national capital."
        },
        {
            faqQuestion: "Is travel feasible from property in Delhi?",
            faqAnswer: "Yes, the road connectivity and metro accessibility make travel feasible from property in Delhi."
        },      


    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };
    return (
        <div>
            <Helmet>
                <title>Top Real Estate Company, Consultant in Delhi | Star Estate</title>
                <meta name="description" content='Star Estate - Top Real Estate Company in Delhi. We are the premier broker and best consultant in Delhi for all your property requirements. Contact us soon.' />
                <meta name="keywords" content='Real Estate Company In Delhi, Best Real Estate Company In Delhi, Leading Real Estate Company In Delhi, Top Real Estate Company In Delhi, Real Estate Broker In Delhi, Top Real Estate Broker In Delhi, Leading Real Estate Broker In Delhi, Best Real Estate Broker In Delhi, Real Estate Consultant In Delhi, Top Real Estate Consultant In Delhi, Best Real Estate Consultant In Delhi, Leading Real Estate Consultant In Delhi' />
            </Helmet>
            <Header />

            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-emi-calculator-m.jpg" />
                    <img src="../assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Top real estate company in Delhi</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h1 className="h3 mb-0">Top real estate company in Delhi</h1>
                    </div>

                    <div className="buyer-text-box mb-4">
                        <article>
                            <p className='mb-4'><span className="text-green">Star Estate</span> more than a decade-long experience in understanding property buyers'/investors' sentiments makes them the top <strong>real estate company in Delhi</strong>. The organization is a team of seasoned sales experts, researchers, and digital professionals who tailor the best property purchase equation to cater clients across verticals.</p>
                            <p className='mb-4'>The organization aims for clients' investment satisfaction and their happy faces make a deal successful for <a href="https://www.starestate.com/" target="_blank" rel="noopener noreferrer">Star Estate</a>. With a grand property horizon in the offering, the company is delighted to attain a magic figure of happy clientele. With real estate market sentiment shooting up at a rocket speed, apprehensions do ascend amongst investors. Star Estate is a one-stop solution to them all which restricts investors from stepping into the market.</p>

                            <h2>Reasons for Star Estate being Top Real Estate Consultant in Delhi</h2>
                            <p className='mb-4'>Star Estate has always maintained to be a customer-centric consultant with an innovative approach. The company’s pan India presence with reputed realtor brands offers investors profuse options. Innovative ideas, technological support, and customized approach make Star Estate, a <strong>leading real estate consultant in Delhi</strong>. The company delightfully presents a property platter with luxury apartments, penthouses, and independent floors. In the commercial vertical of the Indian real estate market, Star Estate is the brightest shining star catering retail shops, food courts, kiosks, anchor stores, and hypermarkets.</p>
                        </article>

                        <div className="row mx-0 ">
                            <div className="col-md-6 px-4 pt-4">
                                <section>
                                    <h4>Fortitude and Personalisation</h4>
                                    <p className='mb-4'>In India, property purchase is an emotion and sometimes the process becomes time-consuming. Luckily, Star Estate understands it and expresses fortitude towards clients. In the process, our seasoned sales professionals explain, the project and the benefits lingering with them. A professional approach with a personalized touch is reflected in our business.</p>

                                    <h4>Zero Manipulative Tactics</h4>
                                    <p className='mb-4'>Star Estate, the <strong>leading real estate company in Delhi</strong> feels honesty pays always. Thus, the company’s one of the main ethics is TRANSPARENCY. With this, the company achieved many milestones in the real estate domain. First and foremost the company lists inventory with the actual offering price. Star Estate does not indulge in hide-and-seek games with investors. The company believes in pitching the actual offer. Hence, investors never experience hindrances in the property purchase journey</p>

                                    <h4>Redefining Realty</h4>
                                    <p className='mb-4'>A property investment in India tailors multiple dreams like kids’ financial security. An opportunity to build a legacy for the next generation. The organization’s logical interpretation of diverse portfolios available in the property market leads to righteous investment decisions. The fairly priced deals for clients, make Star Estate, the <strong>best real estate Broker in Delhi</strong>.</p>

                                    <h4>Client is the King</h4>
                                    <p className='mb-4'>At Star Estate, there isn’t any room for delay in client meetings. Mainly because, at times, clients’ come up with apprehensions with context from floating real estate news. Star Estate’s seasoned sales professionals show up on time to make the customer comfortable. The logical answer from the organisation vanishes clients’ concerns letting them take a strong investment portfolio home.</p>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <img src="https://www.starestate.in/assets/images/pune.webp" className="object-cover h-100" alt="Vision" />
                            </div>
                        </div>

                        <div className="row mx-0 flex-row-reverse ">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h2>Star Estate Values Assure Success | Top Real Estate Broker in Delhi</h2>
                                    <h4>Transparency</h4>
                                    <p className='mb-4'>Listening is the key to success, and our team patiently listens to the client's requirements and expectations. With it, we begin the procedure of listing, searching, and shortlisting properties for clients. The process includes picking up budget-friendly inventory and describing the project embellished with features that make the dream living experience a fairy tale.</p>
                                    <h4>Reliability</h4>
                                    <p className='mb-4'>With the transparent work approach, we have won the trust of clients across segments. Thus, clienteles refer us to friends and family as they know Star Estate is a reliable name in the Indian real estate market.</p>
                                    <h4>Sincerity</h4>
                                    <p className='mb-4'>The company sticks to their word. Star Estate never steps back from the commitment. Our professional work approach and the precision to evaluate and meet client expectations draw our proven commitment since inception.</p>
                                    <h4>Assurance</h4>
                                    <p className='mb-4'>Star Estate assures investors about closing the right deal. The company addresses property investment purposes in the wake to meet client satisfaction. The organisation exhibits relevant properties for investors with assurance of post-sale.</p>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <img src="https://www.starestate.in/assets/images/top-real-estate-company-in-india1.webp" className="object-cover h-100" alt="Mission" />
                            </div>
                        </div>

                        <div className="row mx-0 ">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h3>Innovative Leading Real Estate Consultant in Delhi</h3>
                                    <p className='mb-4'>Star Estate is a new-age digital property consultancy platform available with headquarter in Noida. The use of innovative technology like AI (Artificial Intelligence), and VR (Virtual reality) has a significant role in the property business. With plentiful luxury projects under its banner, the company offers colossal options to investors. An abundant inventory across diverse portfolios, Star Estate, is the <strong>best real estate Consultant Company in Delhi</strong> to dial for investment.</p>
                                    <p className='mb-4'>With technology comes zero hidden policy at the organization as investors get the exact deal. The tech support ensure fast-paced connectivity with investors to ensure the closing of a lucrative deal. Thanks to technology, the organisation maintains records like available projects, inventory, and budget to meet client’s requirements.</p>
                                    <p className='mb-4'>In the present time, technology does spread the word, and Star Estate roars on the platform with thousands of satisfied clients. With a bandwagon of diverse portfolios and reputed <strong>leading real estate consultancy company in Delhi</strong>, Star Estate is the . </p>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <img src="https://www.starestate.in/assets/images/top-real-estate-company-in-india2.webp" className="object-cover h-100" alt="Mission" />
                            </div>
                        </div>

                        <div className="row mx-0 flex-row-reverse ">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h3>Why Choose Star Estate | Best Real Estate Company In Delhi</h3>
                                    <p className='mb-4'>Since its inception, Star Estate dealt in the luxury real estate segment and has met its promise to render the best investment options. From villas, and penthouses to high-street shopping destinations, the company deals in every vertical. In the luxury segment, the company deals with ready-to-move, under-construction, pre-launch, and possession soon properties.</p>
                                    <p className='mb-4'>With immense investment opportunities and transparent work approach, Star Estate becomes the best <strong>real estate company in Delhi</strong>.</p>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <img src="https://www.starestate.in/assets/images/pune-2.webp" className="object-cover h-100" alt="Mission" />
                            </div>
                        </div>
                    </div><div className="heading mx-auto text-center">
                        <h2 className="mb-0">Frequently Asked Questions</h2>
                    </div>
                    {Faqs.length > 0 && (
                        <div className="">
                            <div className="accordion-wrapper" id="myAccordion">
                                {Faqs.map((faq, index) => {
                                    const faqIndex = index + 1;
                                    const isActive = activeIndex === faqIndex; // Check if this item is active

                                    return (
                                        <div className="accordion-item" key={faqIndex}>
                                            <div className="accordion-header" id={`heading${faqIndex}`}>
                                                <button
                                                    className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => handleToggle(faqIndex)}
                                                    aria-expanded={isActive ? "true" : "false"}
                                                    aria-controls={`collapse${faqIndex}`}
                                                >
                                                    <span className='text-primary'>Q{faqIndex}:</span> <div dangerouslySetInnerHTML={{ __html: faq.faqQuestion }} />
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                            <div
                                                id={`collapse${faqIndex}`}
                                                className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                aria-labelledby={`heading${faqIndex}`}
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <div className="card-body">
                                                        <div>{faq.faqAnswer}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>


    )
}
export default TopEstateDelhi