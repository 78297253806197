import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../../widgets/header';

function TopEstateGhaziabad() {

    const Faqs = [
      { faqQuestion: 'In what verticals do Star Estate deal?', faqAnswer: 'Star Estate deals in luxury and ultra-luxury property segments.' },
      { faqQuestion: 'Is Star Estate RERA-registered and holds the rights to operate in Ghaziabad city?', faqAnswer: 'Yes. Star Estate is a RERA registered company with rights reserved to function in Ghaziabad’s real estate market.' },
      { faqQuestion: 'How can I connect with Star Estate?', faqAnswer: 'Get in touch with our sales manager to discover property options in Ghaziabad.' },
      { faqQuestion: 'With which developers does Star Estate, the top real estate agent in Ghaziabad, works?', faqAnswer: 'In Ghaziabad, Star Estate works with ATS Homekraft, Wave Group and Prateek group.' },
      { faqQuestion: 'Does Star Estate deal in luxury property in Ghaziabad?', faqAnswer: 'Yes, Star Estate offers luxury properties in Ghaziabad across sizes and budgets.' },
      { faqQuestion: 'Does Star Estate offer after-sale assistance to property investors?', faqAnswer: 'That’s the USP of Star Estate. The company offers after-sale assistance to property investors.' },
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        // If the clicked item is already active, deactivate it by setting the activeIndex to null
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };


    return (
        <div>
            <Header />
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-emi-calculator-m.jpg" />
                    <img src="../assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Top real estate company in Ghaziabad</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h1 className="h3 mb-0">Top real estate company in Ghaziabad</h1>
                    </div>     

                        <div className="buyer-text-box mb-4">                       
                        <article>
        <p className='mb-4'>
            <span className="text-green">Star Estate</span> is a technology-enabled and innovative property search platform founded by Mr. Vijay Jain. 
            The company is the <strong>top real estate consultant in Ghaziabad</strong> with the vision to seamlessly enhance the property purchase journey. 
            Established in 2012, the company consistently meets property investors’ expectations with a tech-savvy, legal, and result-oriented approach.
          </p>
        <p className='mb-4'><a href="https://www.starestate.com/" target="_blank" rel="noopener noreferrer">Star Estate</a> 
             has earned the satisfaction of over 1 lakh clients, building trust that propels the company forward to expand into other Indian cities.
          </p>
       
      </article>
        
          <div className="row mx-0">
      <div className="col-md-6 mv-box px-4 pt-4">
        <section>
          <h4>Who We Are</h4>
          <p className='mb-4'>The pursuit of having a team of professionals overwhelms Star Estate with the honor and inspiration to set new benchmarks in the real estate market. The company has three pillars of strength. They are Transparency, reliability, and proven commitment. We are proud to find a strong foundation in the Indian real estate market without divulging from ethos.</p>
          <p className='mb-4'>With Star Estate, the <strong>leading real estate company in Ghaziabad</strong>, property purchase is a joyride. The company values budget limitations and offers client-centric property options. With us explore pre-lease retail shops, lockable office spaces, and studio apartments.</p>
          <p className='mb-4'>The organisation is a bunch of seasoned and young professionals with the zest to cater to property investors. With distinguished property options, the company renders luxury and ultra-luxury immovable assets. Thanks to the dynamic real estate enthusiasts on board, Star Estate has a reputation as a <strong>trustworthy real estate consultant in Ghaziabad.</strong></p>
        </section>
      </div>
      <div className="col-md-6 mv-img px-0">
        <img src="https://www.starestate.in/assets/images/pune.webp" className="object-cover h-100" alt="Vision" />
      </div>
    </div>
          
          <div className="row mx-0 flex-row-reverse">
          <div className="col-md-6 mv-box px-4 pt-4">
            <section>
              <h2>Why Choose Us: Star Estate is Leading Real Estate Consultant in Ghaziabad</h2>
              <p className='mb-4'><strong>Well-Versed Working</strong> – With over a decade of experience in rendering real estate consultancy, Star Estate is a bastion to explore. The investors here experience transparent and timely assistance. From replying to a query, site visit, and booking to after-sales assistance, the company takes care of every process. An overall property solution without a hassle, makes Star Estate, the <strong>top real estate broker in Ghaziabad</strong>.</p>              <p className='mb-4'><strong>Strong Technical Backend</strong> – Utilizing AI and VR, we enhance operational efficiency and provide user-friendly solutions.</p>
              <p className='mb-4'><strong>Strong Technical Backend</strong> – The inclusion of AI (Artificial Intelligence) and VR (Virtual Reality), in business strengthens operations. The symphony between these tech tools, answers clients' queries easily. With easy project details and record maintenance, Star Estate, the <strong>leading real estate consultant in Ghaziabad</strong> builds user-friendly strategies.</p>              <p className='mb-4'><strong>Futuristic Prospects</strong> – From luxury apartments to gated communities, we offer a range of immovable assets, establishing Star Estate as the <strong>best real estate consultant in Ghaziabad</strong>.</p>
              <p className='mb-4'><strong>Innovative Attitude</strong> – With a transformative lifestyle, investors' preferences also witness a chance. Thus, the Noida-based organization offers diverse portfolios in the Indian real estate market across the country. Star Estate’s innovative work approach, makes investors understand the benefit of investing in co-working, pod, retail shops, kiosks, and food courts.</p>            </section>
              <p className='mb-4'><strong>Futuristic Prospects</strong> – Bygone is the time when real estate meant flats and houses. Today, Star Estate brings to you a wide range of immovable properties. From independent floors, luxury apartments, and gated communities, to more. With so much to offer on the platter, Star Estate is the <strong>best real estate consultant in Ghaziabad</strong>. </p>
              <p className='mb-4'><strong>Valuable Investment</strong> – Investment is a dream to reap high returns and Star Estate is the trustworthy partner in the journey towards it. The organisation suggests best commercial and residential properties in the market with high investment prospects.</p>
          </div>
          <div className="col-md-6 mv-img px-0">
            <img src="https://www.starestate.in/assets/images/top-real-estate-company-in-noida2.webp" className="object-cover h-100" alt="Mission" />
          </div>
        </div>
  <div className="row mx-0">
    <div className="col-md-6 mv-box px-4 pt-4">
      <section>
       
      <p className='mb-4'>With a professional approach and timely assistance, Star Estate has become the <strong>leading real estate consultant in Ghaziabad</strong>. With the bandwagon of seasoned professionals, investors experience hassle-free property investment. </p>
      <p className='mb-4'>Thus, Star Estate is the <strong>best real estate broker in Ghaziabad</strong> to connect for property investment. </p>
      </section>
    </div>
    <div className="col-md-6 mv-img px-0">
      <img 
        src="https://www.starestate.in/assets/images/pune-2.webp" 
        className="object-cover h-100" 
        alt="Mission" 
      />
    </div>
  </div>
        </div>
        </div>
        <div className="w-100 padding bg-gray-gradient-box section-faq">
                {Faqs.length > 0 && (
                    <div className="container-lg">
                        <div className="heading mx-auto text-center">
                            <h2 className="mb-0">FAQs</h2>
                        </div>
                        <div className="">
                            <div className="accordion-wrapper" id="myAccordion">
                                {Faqs.map((faq, index) => {
                                    const faqIndex = index + 1;
                                    const isActive = activeIndex === faqIndex; // Check if this item is active

                                    return (
                                        <div className="accordion-item" key={faqIndex}>
                                            <div className="accordion-header" id={`heading${faqIndex}`}>
                                                <button
                                                    className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => handleToggle(faqIndex)}
                                                    aria-expanded={isActive ? "true" : "false"}
                                                    aria-controls={`collapse${faqIndex}`}
                                                >
                                                    <span className='text-primary'>Q{faqIndex}:</span> <div dangerouslySetInnerHTML={{ __html: faq.faqQuestion }} />
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                            <div
                                                id={`collapse${faqIndex}`}
                                                className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                aria-labelledby={`heading${faqIndex}`}
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <div className="card-body">
                                                        <div>{faq.faqAnswer}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}</div></div></div>
          
        
    )
}
export default TopEstateGhaziabad