import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ThankYou = () => {
  useEffect(() => {
    // Check if the form was submitted
    const wasFormSubmitted = localStorage.getItem('formSubmitted');

    if (wasFormSubmitted) {
      // Reload the page once
      window.location.reload();
      // Clear the item from localStorage so it doesn't reload again
      localStorage.removeItem('formSubmitted');
    }
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light text-center">
      <picture>
        <source media="(max-width: 768px)" srcSet="assets/images/Thank-You-page-image.jpg" />
        <img 
          src="assets/images/Thank-You-page-image.jpg" 
          alt="Thank You Illustration" 
        />
      </picture>
      <Link to="/" className="btn btn-warning btn-lg" style={{ marginTop: '20px', marginBottom: '20px' }}>
        Go to Home
      </Link>
    </div>
  );
};

export default ThankYou;
