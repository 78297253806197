import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../../widgets/header';

function TopEstateFaridabad() {

    const Faqs = [
        {
            faqQuestion: 'Does Star Estate leading real estate broker in Faridabad offer property options across the city?',
            faqAnswer: 'Yes. The company deals in property market across the city, mainly Sector 43, 79, and Sector 86.',
          },
          {
            faqQuestion: 'Does Star Estate offer RERA-approved projects in Faridabad?',
            faqAnswer: 'Yes. Star Estate offers RERA-approved projects in Faridabad.',
          },
          {
            faqQuestion: 'Does Star Estate offer 4 BHK luxury apartments in Faridabad? How can I make a site visit to them?',
            faqAnswer: 'Star Estate offers 4 BHK luxury apartments in Faridabad. To schedule a site visit, connect with our sales manager now.',
          },
          {
            faqQuestion: 'Star Estate works with which developer in Faridabad?',
            faqAnswer: 'In Faridabad, Star Estate works with Omaxe Group, a reputed developer in India.',
          },
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        // If the clicked item is already active, deactivate it by setting the activeIndex to null
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };


    return (
        <div>
            <Header />
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-emi-calculator-m.jpg" />
                    <img src="../assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Top real estate company in Faridabad</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h1 className="h3 mb-0">Top real estate company in Faridabad</h1>
                    </div>     

                        <div className="buyer-text-box mb-4">                       
                        <article>
                <header>
                    <p className='mb-4'><span className="text-green">Star Estate</span>has a strong presence in the property market of the city. It is a Noida-based real estate consultant with a team of dynamic real estate professionals. The strong values and ethics make the organization's clients trustworthy property purchase companion.</p>
                    <p className='mb-4'>The company aims to answer clients’ investment apprehensions making them bet on the ideal immovable asset.</p>
                </header>
               </article>

            <div className="row mx-0">
                <div className="col-md-6 mv-box px-4 pt-4">
                    <section>
                        <h3>Our Work Approach | Best Real Estate Consultant in Faridabad</h3>
                        <p className='mb-4'>Star Estate, the top real estate consultant in Faridabad, has a result-oriented work approach. With stakes high in the market to fulfil commitments, the company leaves no stone unturned. Star Estate deals in luxury and ultra-luxury residential projects. It also caters commercial properties to investors in the city with multiple options.</p>
                       
                    </section>
                </div>
                <div className="col-md-6 mv-img px-0">
                    <figure>
                        <img src="https://www.starestate.in/assets/images/pune.webp" className="object-cover h-100" alt="Mission"/>
                    </figure>
                </div>
            </div>

            <div className="row mx-0 flex-row-reverse">
                <div className="col-md-6 mv-box px-4 pt-4">
                <section>
                <h3>Star Estate Top Real Estate Broker in Faridabad | Raising Stakes</h3>
                            <p className='mb-4'>Star Estate is a trusted name in the Indian property market, established to connect with last-mile property buyers. The timely assistance and zero hidden charges are the two USPs of the company.</p>
                            <p className='mb-4'>Partnering with the reputed Omaxe Group, the company has raised the bar of business in the city. Star Estate is a well-known name in the real estate realm.</p>


                        </section>
                </div>
                <div className="col-md-6 mv-img px-0">
                    <figure>
                        <img src="https://www.starestate.in/assets/images/pune-2.webp" className="object-cover h-100" alt="Vision"/>
                    </figure>
                </div>
            </div>


            <div className="row mx-0">
                <div className="col-md-6 mv-box px-4 pt-4">
                    <section>
                        <h3>Best Real Estate Company in Faridabad | What Make Us Different from Others</h3>
                        <p className='mb-4'>Star Estate’s mission is to eliminate woes that restrict investors from entering into the real estate market. The Noida-based company’s sturdy technology-built backend, caters to every property requirement. Right from city-wise to residential and commercial properties, there are plenteous immoveable assets.</p>
                       <p className='mb-4'>The company’s transparent work approach is an aid to investors in the city to find a fairly-priced property.</p>
                    </section>
                </div>
                <div className="col-md-6 mv-img px-0">
                    <figure>
                        <img src="https://www.starestate.in/assets/images/top-real-estate-company-in-gurgaon2.webp" className="object-cover h-100" alt="Mission"/>
                    </figure>
                </div>
            </div>

            <div className="row mx-0 flex-row-reverse">
                <div className="col-md-6 mv-box px-4 pt-4">
                <section>
                <h3>Star Estate | Best Real Estate Broker in Faridabad</h3>
                            <p className='mb-4'>A dream property is attainable now in your city.</p>
                            <p className='mb-4'>Star Estate is the best real estate broker in Faridabad to connect if property investment is on your mind. The company’s entire assistance process, even the after-sale is appreciable.</p>
                            <p className='mb-4'>With the bandwagon of professionals, the company aims to put an end to property purchase worries. The organization with 12 years+ legacy of delivering happy spaces, covers the major localities of the city.</p>
                            <p className='mb-4'>Star Estate, the leading real estate consultant in Faridabad deals in multiple sectors. The company renders luxury apartments in Sector 43, 79, and 86. In the city, the company majorly works with the famous Omaxe Group.</p>
                        </section>
                </div>
                <div className="col-md-6 mv-img px-0">
                    <figure>
                        <img src="https://www.starestate.in/assets/images/pune-2.webp" className="object-cover h-100" alt="Vision"/>
                    </figure>
                </div>
            </div>
        </div>
        </div>
        <div className="w-100 padding bg-gray-gradient-box section-faq">
                {Faqs.length > 0 && (
                    <div className="container-lg">
                        <div className="heading mx-auto text-center">
                            <h2 className="mb-0">FAQs</h2>
                        </div>
                        <div className="">
                            <div className="accordion-wrapper" id="myAccordion">
                                {Faqs.map((faq, index) => {
                                    const faqIndex = index + 1;
                                    const isActive = activeIndex === faqIndex; // Check if this item is active

                                    return (
                                        <div className="accordion-item" key={faqIndex}>
                                            <div className="accordion-header" id={`heading${faqIndex}`}>
                                                <button
                                                    className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => handleToggle(faqIndex)}
                                                    aria-expanded={isActive ? "true" : "false"}
                                                    aria-controls={`collapse${faqIndex}`}
                                                >
                                                    <span className='text-primary'>Q{faqIndex}:</span> <div dangerouslySetInnerHTML={{ __html: faq.faqQuestion }} />
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                            <div
                                                id={`collapse${faqIndex}`}
                                                className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                aria-labelledby={`heading${faqIndex}`}
                                                data-bs-parent="#myAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <div className="card-body">
                                                        <div>{faq.faqAnswer}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}</div></div></div>
          
        
    )
}
export default TopEstateFaridabad