import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../../../widgets/header';

function TopEstatePune() {

    const Faqs = [
        {
            faqQuestion: "With which developers does Star Estate work in Pune?",
            faqAnswer: "Star Estate is a leading real estate broker in Pune and works with Godrej Properties, Kolte-Patil, Lodha Group, and VTP Realty."
        },
        {
            faqQuestion: "What type of properties does Star Estate offer?",
            faqAnswer: "Star Estate offers residential, commercial, and all other types of properties in the city."
        },
        {
            faqQuestion: "Does Star Estate offer 3 BHK apartments in Pune?",
            faqAnswer: "Yes, Star Estate, the top real estate consultant in Pune offers 3 BHK apartments."
        },
        {
            faqQuestion: "How can I connect with Star Estate?",
            faqAnswer: "Get in touch with our sales manager to explore Pune’s property market."
        },
        {
            faqQuestion: "Does Star Estate work with RERA-registered developers only?",
            faqAnswer: "Of course. Star Estate works with RERA-registered developers and on RERA-approved projects only."
        }
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };


    return (
        <div>
           
            <Helmet>
                <title>Top Real Estate Company in Pune, Best Consultant, Broker </title>
                <meta name="description" content="Discover properties with Star Estate, Pune's top real estate broker firm. Trust the top real estate company in Pune. Reach out to us for expert property consultancy." />
                <meta name="keywords" content=' Real Estate Company In Pune, Best Real Estate Company In Pune, Leading Real Estate Company In Pune, Top Real Estate Company In Pune, Real Estate Broker In Pune, Top Real Estate Broker In Pune, Leading Real Estate Broker In Pune, Best Real Estate Broker In Pune, Real Estate Consultant In Pune, Top Real Estate Consultant In Pune, Best Real Estate Consultant In Pune, Leading Real Estate Consultant In Pune ' />
            </Helmet>
            <Header />
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-emi-calculator-m.jpg" />
                    <img src="../assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Top real estate company in Pune</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h1 className="h3 mb-0">Top real estate company in Pune</h1>
                    </div>

                    <div className="buyer-text-box mb-4">
                        <article>
                            <header>
                                <p className='mb-4'><span className="text-green">Star Estate</span> is a <strong>leading real estate broker in Pune</strong> with a professional team. At the workplace, investors' wishes are the company’s command, and there's a balance of seasoned and fresh professionals. With experienced and quick learners side by side, <a href="https://www.starestate.com/godrej-palm-retreat" target="_blank" rel="noopener noreferrer">Star Estate</a> has become a top real estate company in Pune.</p>
                            </header>
                            <h2>Top Real Estate Consultant in Pune – Star Estate</h2>
                            <p className='mb-4'>With the team of professionals refining business strategies and decoding analytics, Star Estate is continuously moving ahead with time. At business, the company understands the power of client satisfaction. Thus, the sales team architecture does everything necessary to attain the same.</p>
                            <p className='mb-4'>Star Estate is the <strong>top real estate consultant in Pune</strong> as the organization chases inventory that meets clients’ expectations. To deliver the best-in-class property investment experience, the company works on varied mechanisms. </p>            </article>

                        <article>
                            <header>
                                <h2 className="h3">Tactical Practices that Make Star Estate a Leading Real Estate Broker in Pune</h2>
                            </header>
                            <ul className="list mb-0">
                                <li><strong>Research Algorithm </strong> - Each property buyer is family to us. We hold back our horses until the buyer is sure about the property buying decision. Our sales experts explain property dynamics to offer in-depth details of the immovable asset. With an intense analysis, we empower buyers with the opportunity to make a beneficial decision.</li>
                                <li><strong>Transparency is the Key</strong> - Counting on figures is pivotal in business, but client satisfaction is equally important. Star Estate, the <strong>best real estate company in Noida</strong>, outperform competitors with 100% transparency in business. We list ideal property prices, instead of saying something now and adding hidden charges later in the deal. Our motto in business is to meet clients' expectations without using manipulating tactics. </li>
                                <li><strong>Research Algorithm</strong> – Research is the base for everything, and Star Estate has a team of researchers to perform the task daily. Our colossal team of researchers digs out all the necessary information about property prices and market trends.</li>

                                <li><strong>Customer Satisfaction</strong> – Star Estate’s work approach makes the organization a Leading real estate broker in Pune. The company never makes the client wait! With a structured work pattern, serving clients with the best inventory option is feasible. Starting with the outline of client requirements, the company draw a coherent work plan to put in place. The at-office discussion, site visits, and after-sales assistance are aligned to achieve client satisfaction. </li>

                                <li><strong>Tech-Enabled Assistance</strong> – From project PDFs to video tours there are various bows in Star Estate’s quiver. Many other tech-enabled practices make the company, a leading real estate consultant in Pune. </li>

                                <li><strong>Distinct Verticals</strong> – The property market and investors’ aspirations have come a long way. Thus, ample verticals in real estate have evolved. With so much in the offering, investors expect clarity. Star Estate, the leading real estate company in Pune answers it all. The company deals in residential, commercial, plots, and other spheres. </li>

                                <li><strong>After Sale Assistance</strong> – The organization believes in building relationships and legacies. Hence, Star Estate renders after sale assistance until the client’s apprehensions are answered. At the company, each sales resource is available for clients till he/she is settled in the happy space.</li>

                            </ul>
                        </article>

                        <div className="row mx-0 ">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h3>Star Estate Work Sphere – Top Real Estate Company in Pune</h3>
                                    <p className='mb-4'>Success isn’t attainable overnight. To accomplish it one has to ascend the trails of practice. Star Estate, the <strong>Top Real Estate Company in Pune</strong>, does the same. </p>
                                    <p className='mb-4'>The organisation with STAR in its name, shines like a real star on the horizon of Pune real estate. Dedication, determination, and discipline are the three bedrock on which the company stands strong.</p>
                                    <ul class="list mb-0">
                                        <li><strong>Dedication</strong> – The practice of passionately resolving customer property queries with sincerity makes the organization the best in the market. The team is always there to clear doubts over property titles, capital appreciation, and other property-related queries.</li>

                                        <li><strong>Determination</strong> – Since its inception, Star Estate’s motive has been to be goal-centric. Thus, the determination to lead clients with the confidence to chase the dream property, makes Star Estate different. The company is determined to deliver excellent property investment services to clientele across segments. That is why Star Estate is the top real estate consultant in Pune.</li>

                                        <li><strong>Discipline</strong> – Businesses are layered. But, discipline in actions, words, and commitment make Star Estate, the best real estate broker in Pune. The company sticks to its promise once given to the client. No stone is left unturned to accomplish investors’ property goals. </li>

                                    </ul>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <figure>
                                    <img src="https://www.starestate.in/assets/images/pune-2.webp" className="object-cover h-100" alt="Mission" />
                                </figure>
                            </div>
                        </div>

                        <div className="row mx-0 flex-row-reverse ">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h6>Who We Are – Star Estate </h6>
                                    <p className='mb-4'>Star Estate is a pioneer in the real estate domain with a strong workforce and technology support. The company is based in Noida and has a pan-India presence while being the best real estate company in Pune.</p>
                                    <p className='mb-4'>Since its establishment, the company is working with reputed developers like Godrej Properties, Kolte-Patil, Lodha Group, and VTP Realty. The organization works with a ground breaking passion to turn property dreams into reality.</p>
                                    <p className='mb-4'>Star Estate is a team of positive minds who crave to score maximum client satisfaction. The company has a strong presence in top metro cities, and renders the best inventory to clientele.</p>
                                    <p className='mb-4'>The company is a top real estate broker in Pune all because of its seamless result-driven work approach.</p>

                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <figure>
                                    <img src="https://www.starestate.in/assets/images/pune.webp" className="object-cover h-100" alt="Vision" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 padding bg-gray-gradient-box section-faq">
                    {Faqs.length > 0 && (
                        <div className="container-lg">
                            <div className="heading mx-auto text-center">
                                <h2 className="mb-0">FAQs</h2>
                            </div>
                            <div className="">
                                <div className="accordion-wrapper" id="myAccordion">
                                    {Faqs.map((faq, index) => {
                                        const faqIndex = index + 1;
                                        const isActive = activeIndex === faqIndex; // Check if this item is active

                                        return (
                                            <div className="accordion-item" key={faqIndex}>
                                                <div className="accordion-header" id={`heading${faqIndex}`}>
                                                    <button
                                                        className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                        type="button"
                                                        onClick={() => handleToggle(faqIndex)}
                                                        aria-expanded={isActive ? "true" : "false"}
                                                        aria-controls={`collapse${faqIndex}`}
                                                    >
                                                        <span className='text-primary'>Q{faqIndex}:</span> <div dangerouslySetInnerHTML={{ __html: faq.faqQuestion }} />
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                                <div
                                                    id={`collapse${faqIndex}`}
                                                    className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                    aria-labelledby={`heading${faqIndex}`}
                                                    data-bs-parent="#myAccordion"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="card-body">
                                                            <div>{faq.faqAnswer}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}</div></div></div>


    )
}
export default TopEstatePune