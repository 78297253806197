import React, { useEffect, useState } from 'react';
import Banner from "./banner";
import CitywiseContainer from "./cities";
import InsightsTools from "./insight_tools";
import ProjectSlider from "./luxury_projects";
import NewsViews from "./news_views";
import SocialMediaFeed from "./social_media";
import { Helmet } from 'react-helmet';

export default function Home() {

    return (          
            <>
                <Helmet>
                    <title>Best Real Estate Consultancy Company in India | Star Estate</title>
                    <meta name="description" content="Star Estate is the Best Real Estate Consultancy Company In India. Browse Extensive Range Of Luxury Residential and Commercial Properties in India. Explore Now." />
                    <meta name="keywords" content="Real Estate Consultancy Company, Real Estate Consultancy Company in India, Best Real Estate Consultancy,  Real Estate Company, Real Estate company in India, " />
                </Helmet>

                <Banner />
                <InsightsTools />
                <ProjectSlider />
                <CitywiseContainer />
                <NewsViews />
                <SocialMediaFeed />
            </>
    );
}
