import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../../widgets/header';
import { Helmet } from 'react-helmet';

function TopEstateGurgaon() {

    const Faqs = [
        {
            faqQuestion: "What are the services provided by Star Estate?",
            faqAnswer: "Star Estate is a leading real estate consultant in Gurgaon that provides property purchase services."
        },
        {
            faqQuestion: "Does Star Estate, the top real estate broker in Gurgaon offer pre-sales assistance?",
            faqAnswer: "Yes, Star Estate deals in pre-sale assistance."
        },
        {
            faqQuestion: "What type of projects does Star Estate deal with?",
            faqAnswer: "Star Estate deals in luxury residential properties like penthouses, villas, and 5BHK apartments."
        },
        {
            faqQuestion: "Star Estate works in which localities of Gurugram city?",
            faqAnswer: "The company deals in the South of Gurugram, Golf Course extension road, Gwal Pahari, and Dwarka Expressway."
        },
        {
            faqQuestion: "What type of properties does Star Estate deal in?",
            faqAnswer: `Star Estate deals in residential apartments, retail shops, food courts, high-street shopping malls, kiosks, and IT suites.`
        },
        {
            faqQuestion: "With which developers does Star Estate work in Gurugram?",
            faqAnswer: `Star Estate, the leading real estate broker in Gurugram, deals with Signature Global and Vatsal Valley.`
        },
        {
            faqQuestion: "Does Star Estate hold a RERA registration number for offering property consultancy services in Gurgaon?",
            faqAnswer: `Yes. Star Estate has a RERA registration number and sticks by the rules laid by the RERA authority.`
        }


    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        // If the clicked item is already active, deactivate it by setting the activeIndex to null
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };


    return (
        <div>
            <Helmet>
                <title>Top Real Estate Company in Gurgaon, Consultant, Broker | Star Estate</title>
                <meta name="description" content='Discover excellence with Star Estate, top real estate company in Gurgaon. Best Broker in Gurgaon for your property needs. Contact us for property consultants.' />
                <meta name="keywords" content=' Real Estate Company In Gurgaon, Best Real Estate Company In Gurgaon, Leading Real Estate Company In Gurgaon, Top Real Estate Company In Gurgaon, Real Estate Broker In Gurgaon, Top Real Estate Broker In Gurgaon, Leading Real Estate Broker In Gurgaon, Best Real Estate Broker In Gurgaon, Real Estate Consultant In Gurgaon, Top Real Estate Consultant In Gurgaon, Best Real Estate Consultant In Gurgaon, Leading Real Estate Consultant In Gurgaon' />
            </Helmet>
            <Header />
            <div className="insideBanner">
                <picture>
                    <source media="(max-width: 820px)" srcSet="../assets/images/banner-emi-calculator-m.jpg" />
                    <img src="../assets/images/banner-emi-calculator.jpg" className="h-100 object-cover" alt="Star Estate" />
                </picture>
            </div>
            <div className="w-100">
                <div className="container-lg">
                    <div className="breadcrumbContainer" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active">Top real estate company in Gurgaon</li>

                        </ol>
                    </div>
                </div>
            </div>
            <div className="w-100 padding">
                <div className="container-lg">
                    <div className="heading mx-sm-auto text-sm-center">
                        <h1 className="h3 mb-0">Top real estate company in Gurgaon</h1>
                    </div>





                    <div className="buyer-text-box mb-4">

                        <article>
                            <p>
                                <b><span className="fa-2x">S</span>tar Estate, the top real estate company in Gurgaon,</b> caters to new-age millennials with luxury living addresses. The organisation with 12 years of learning and a skillful approach, continuously witnessing the changing dynamics of property market. More than buyers, the city is loaded with investors. Understanding long-term investment, we offer an extensive range of <a href="https://www.starestate.com/city/gurugram" target="_blank" rel="noopener noreferrer">property in Gurugram</a>.
                            </p>
                            <p>
                                <a href="https://www.starestate.com" target="_blank" rel="noopener noreferrer">Star Estate</a>, a leading real estate broker in Gurgaon, presents vivid options. From luxury homes with landscape views to retail shops in high-street shopping malls, the platter has everything. We have a seasoned team working on minimalistic details to end apprehensions about property investment.
                            </p>
                            <p>
                                Star Estate, the best real estate consultant in Gurgaon, has immense options to explore. We have properties that do justice with a price tag, location, and amenities in the offering.
                            </p>
                            <p>
                                <p>Our precise work network, extensive property range, and innovation think tank culminate choicest properties for clients. Star Estate, the best real estate agent in Gurgaon, respects budget-limit but won’t let it become a roadblock in the property purchase journey. From affordable luxury <a href="https://www.starestate.com/city/apartments-in-gurugram" target="_blank" rel="noopener noreferrer">Apartments in Gurugram</a> to premium penthouses, we offer something for everyone. </p>
                            </p>
                        </article>
                        <div className="row mx-0">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h6><span className="text-green">How We Work:</span> Star Estate Top Real Estate Broker in Gurgaon</h6>
                                    <ul className="list ml-3 mb-3">
                                        <li><strong>Planning</strong> - Accomplishment of goals is unattainable without proper planning.</li>
                                        <li><strong>Implementation</strong> - Star Estate, a leading <strong>real estate broker in Gurgaon</strong>, offers professional guidance to clients.</li>
                                        <li><strong>Execution</strong> -  - After a complete assessment and site visit, comes the time to seal the deal. We do not blast the hidden charges bomb on clients. Star Estate, <strong>the top real estate company in Gurgaon</strong>, discusses and close the deal on a happy note. </li>
                                    </ul>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <img src="https://www.starestate.in/assets/images/top-real-estate-company-in-gurgaon1.webp" className="object-cover h-100" alt="Mission" />
                            </div>
                        </div>
                        <div className="row mx-0 flex-row-reverse">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h6><span className="text-green">Star Estate:</span> Best Real Estate Consultant in Gurgaon and Network</h6>
                                    <p>We have been in the business for the past 12 years and have been rooting for property buyers/investors.</p>
                                    <p>In the city adjoining Delhi, the national capital, we are associated with many bigwigs. We take immense pride in sharing that we have handed over possession to thousands of families in Gurugram. Our philosophy of making clients happy with the property platter has been working magnificently. The passion to offer possession with a smile makes us a leading <strong>real estate company in Gurgaon</strong>.</p>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <img src="https://www.starestate.in/assets/images/top-real-estate-company-in-gurgaon2.webp" className="object-cover h-100" alt="Vision" />
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-md-6 mv-box px-4 pt-4">
                                <section>
                                    <h6>Why Choose Star Estate Real Estate Consultant in Gurgaon</h6>
                                    <p>With a professional approach, Star Estate becomes the top real estate consultant in Gurgaon’s real estate domain. Our client-centric work approach helps clienteles in making a perfect investment decision. With after-sale and quick assistance, we register phenomenal client appreciation. Our customized and result-oriented work ethics make thousands of families end the search for dream property ownership.</p>
                                </section>
                            </div>
                            <div className="col-md-6 mv-img px-0">
                                <img src="https://www.starestate.in/assets/images/top-real-estate-company-in-gurgaon3.webp" className="object-cover h-100" alt="Mission" />
                            </div>
                        </div>

                    </div>
                </div>  <div className="w-100 padding bg-gray-gradient-box section-faq">
                    {Faqs.length > 0 && (
                        <div className="container-lg">
                            <div className="heading mx-auto text-center">
                                <h2 className="mb-0">FAQs</h2>
                            </div>
                            <div className="">
                                <div className="accordion-wrapper" id="myAccordion">
                                    {Faqs.map((faq, index) => {
                                        const faqIndex = index + 1;
                                        const isActive = activeIndex === faqIndex; // Check if this item is active

                                        return (
                                            <div className="accordion-item" key={faqIndex}>
                                                <div className="accordion-header" id={`heading${faqIndex}`}>
                                                    <button
                                                        className={`accordion-button ${isActive ? "" : "collapsed"}`}
                                                        type="button"
                                                        onClick={() => handleToggle(faqIndex)}
                                                        aria-expanded={isActive ? "true" : "false"}
                                                        aria-controls={`collapse${faqIndex}`}
                                                    >
                                                        <span className='text-primary'>Q{faqIndex}:</span> <div dangerouslySetInnerHTML={{ __html: faq.faqQuestion }} />
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                                <div
                                                    id={`collapse${faqIndex}`}
                                                    className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
                                                    aria-labelledby={`heading${faqIndex}`}
                                                    data-bs-parent="#myAccordion"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="card-body">
                                                            <div>{faq.faqAnswer}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}</div></div></div>


    )
}
export default TopEstateGurgaon